<template>
  <div class="modal-wrapper">
    <transition name="modal" appear>
      <div class="modal">

                <svg class="curve_dy"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1677.381" height="557" viewBox="0 0 1677.381 557">
  <defs>
    <linearGradient id="linear-gradient" x1="0.635" y1="1" x2="-0.106" y2="-0.351" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#494b5a"/>
      <stop offset="1" stop-color="#23232b"/>
    </linearGradient>
  </defs>
  <path id="Union_10" data-name="Union 10" d="M949.619,557a194.435,194.435,0,0,1-38.9-3.921A192.9,192.9,0,0,1,760.54,402.9a194.435,194.435,0,0,1-3.921-38.9V222c0-106.42-86.58-193-193-193h-616V0h623a194.436,194.436,0,0,1,38.9,3.921A192.9,192.9,0,0,1,759.7,154.1a194.435,194.435,0,0,1,3.921,38.9v61c0,106.421,86.579,193,193,193H1625V557ZM1572.6,227.372a13.02,13.02,0,0,0-10.766-12.183,13.006,13.006,0,0,1,10.769,12.183Z" transform="translate(52.381)" fill="url(#linear-gradient)"/>
</svg>

        <header>
          <div class="title">
            <h1>Изпратете ни запитване</h1>
            <p>
              За фиброцимент - {{ product[0].name }} - може да бъде изрязан в
              желаните размери и до подобни размери на камъка.
            </p>
          </div>

          <img  class="close" @click="close" src="@/assets/products/x.svg" alt="">
        </header>

        <main class="modal--content">
          <section class="img--name" :style="{ color: product[0].color }">
            <img
              :src="
                require(`@/assets/home/section-4/product-${product[1] + 1}.png`)
              "
              alt="product img"
            />
            <p>фиброцимент</p>
            <span>{{ product[0].name }}</span>
          </section>

          <section class="form-wrapper">
            <div class="form">
              <Input
                type="text"
                :id="'name'"
                placeholder="Име и фамилия"
                :disabled="loading"
                :invalid="invalidName"
                @input="invalidName = false"
                required="true"
                v-model="form.name"
                icon="form-name.svg"
                class="input"
              />

              <Input
                type="email"
                :id="'email'"
                placeholder="Email"
                :disabled="loading"
                :invalid="invalidEmail"
                @input="invalidEmail = false"
                required="true"
                v-model="form.email"
                icon="form-mail.svg"
                class="input"
              />

              <Input
                type="phone"
                id="phone"
                :inputmode="true"
                placeholder="Телефон"
                :disabled="loading"
                :invalid="invalidPhone"
                @input="invalidPhone = false"
                required="true"
                v-model="form.phone"
                icon="form-phone.svg"
                class="input"
              />
              <Input
                type="text"
                placeholder="Запитване, бройки, друга информация"
                :disabled="loading"
                :invalid="invalidMessage"
                @input="invalidMessage = false"
                required="true"
                v-model="form.message"
                icon="form-text.svg"
                class="input"
              />

              <Checkbox
                v-model="form.wantSspecifyCut"
                :disabled="loading"
                :label="'Желая изрязване по собствени размери'"
                class="checkbox--wantSspecifyCut"
              />

              <Input
                type="text"
                placeholder="Напишете размерите, които желаете (ширина x дължина x дебелина - mm)"
                :disabled="loading"
                :invalid="invalidSpecifyCut"
                @input="invalidSpecifyCut = false"
                required="true"
                v-model="form.specifyCut"
                icon="form-text.svg"
                class="input wantSspecifyCutInput"
                :class="{wantSspecifyCutInputOpen : form.wantSspecifyCut}"
              />

              <main class="checkbox-andbutton">
                <section>
                  <Checkbox
                    v-model="form.agreed"
                    :disabled="loading"
                    :label="'Съгласен съм'"
                    class="checkbox"
                  />

                  <p class="aggrement-text">
                    Тази форма за контакти събира персонална информация с цел
                    обратна връзка при запитване от ваша страна.Чрез маркиране
                    на отметката , вие доброволно се съгласявате да ни
                    предоставите тази информация
                  </p>
                </section>

                <section>
                  <Button
                    @click="submit"
                    :disabled="loading"
                    :text="'Изпрати'"
                    icon="form-send.svg"
                  ></Button>
                </section>
              </main>
            </div>
          </section>
        </main>
      </div>
    </transition>
  </div>
</template>

<script>
import Input from "@/components/Input";
import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox.vue";

export default {
  props: ["product", " inquryProductIndex"],
  components: {
    Input,
    Button,
    Checkbox,
  },

  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
        specifyCut: "",
        wantSspecifyCut: false,
        agreed: false,
      },
      error: "",
      loading: false,
      invalidName:false,
      invalidEmail:false,
      invalidPhone:false,
      invalidMessage:false,
      invalidSpecifyCut:false,
    };
  },

  methods: {
    close(update) {
      this.$emit("close", update);
    },
    isValid() {
      if (!this.form.name) {
        this.invalidName = true;
        this.$snotify.warning("Въведете вашите имена");
        return false;
      } else if (!this.form.email || !this.validateEmail(this.form.email)) {
        this.invalidEmail = true;
        this.$snotify.warning("Въведете коректен имейл");
        return false;
      } else if (
        !this.form.phone ||
        !this.validatePhoneNumber(this.form.phone)
      ) {
        this.invalidPhone = true;
        this.$snotify.warning("Въведете коректен телефон");
        return false;
      } else if (!this.form.message) {
        this.invalidMessage = true;
        this.$snotify.warning("Съобщението не може да е празно");
        return false;
      } else if (this.form.wantSspecifyCut && !this.form.specifyCut) {
        this.invalidSpecifyCut = true;
        this.$snotify.warning("Не сте оставили информация за размерите");
        return false;
      } else if (!this.form.agreed) {
        this.$snotify.warning("Трябва да се съгласите с Общите условия");
        return false;
      }

      return true;
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatePhoneNumber(phone) {
      const re = /^[0-9 ]+$/;
      return re.test(String(phone).toLowerCase());
    },
    submit() {
      if (this.isValid()) {
        this.loading = true;
        this.form.product = this.product[0].name;
        fetch(`${process.env.VUE_APP_BASE_URI}/email/inquiryProduct`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.form),
        })
          .then((response) => response.json())
          .then((data) => {
            this.success = data.success;
            if (data.success) {
              this.form = {
                name: "",
                email: "",
                phone: "",
                message: "",
                specifyCut: "",
                wantSspecifyCut: false,
                agreed: false,
              };
            }
          })
          .catch((err) => console.log(err))
          .then(() =>
            setTimeout(() => {
              this.loading = false;
              this.$snotify.success("Изпратихте успешно запитване");
            }, 1000)
          );
      }
    },
  },
  mounted() {
    document.body.classList.add("noscroll");
  },
  destroyed() {
    document.body.classList.remove("noscroll");
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/modal.scss";
.modal {
  .curve_dy {
    position: absolute;
    width: 150%;
    height: 100%;
    left: -450px;
    bottom: 10px;
    z-index: 1;
  }
}
.modal--content {
  display: flex;
  padding: 60px 20px 30px 20px;
  justify-content: space-between;
  width: 100%;
  overflow: auto;

  .img--name {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 20;
    p {
      font: normal normal normal 44px/15px Marck Script;
      letter-spacing: -1.98px;
      opacity: 0.2;
    }
    span {
      font: normal normal normal 26px/20px GoogleSans-Regular;
      white-space: nowrap;
    }
    img {
      height: 245px;
      width: 255px;
    }
  }

  .form-wrapper {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 20;
    .form {
      display: flex;
      flex-direction: column;
      max-width: 600px;

      .input {
        margin-bottom: 10px;
        &.wantSspecifyCutInput{
          visibility: hidden;
          opacity: 0;
        }
        &.wantSspecifyCutInputOpen{
          visibility: visible;
          opacity: 1;
        }
      }
      .checkbox--wantSspecifyCut {
        padding-left: 18px;
        margin-bottom: 10px;
      }
      .checkbox-andbutton {
        align-items: center;
        display: flex;
        text-align: left;
        margin-top: 30px;
        position: relative;

        section {
          margin-left: 15px;
        }

        .agreement {
          span {
            font: normal normal normal 14px/17px GoogleSans-Italic;
          }
        }
        .aggrement-text {
          margin-top: 10px;
          font-size: 10px;
          max-width: 70%;
        }
      }
    }
  }
}
@media all and(max-width:$mobile) {
  .modal--content {
    flex-direction: column;

    .img--name {
      align-items: center;
      margin-bottom: 20px;
    }
    .form-wrapper {
      .form {
        max-width: initial;
        .checkbox-andbutton {
          flex-direction: column;
          .aggrement-text {
            max-width: 100%;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
</style>
