<template>
  <div
    class="input-wrapper"
    :class="{
      'input-wrapper--invalid': invalid,
      'input-wrapper--disabled': disabled,
    }"
  >
    <img
      v-if="icon"
      class="icons"
      :src="`${require(`@/assets/home/section-6/${icon}`)}`"
      alt="icon"
    />
    <input
      :autocomplete="autocomplete"
      :value="value"
      :id="id || ''"
      :inputmode="inputmode ? 'numeric' : false"
      :type="type || 'text'"
      :placeholder="placeholder"
      :maxlength="maxlength ? maxlength : 100"
      @keypress.enter="$emit('enter')"
      @input="$emit('input', $event.target.value)"
    />

  </div>
</template>

<script>
export default {
  props: [
    "value",
    "type",
    "placeholder",
    "icon",
    "disabled",
    "autocomplete",
    "maxlength",
    "invalid",
    "id",
    "inputmode"
  ],
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.input-wrapper {
  position: relative;
  width: 100%;
  @include tr();
  &.input-wrapper--invalid {
    input {
      border-bottom: 1px solid $mainError;
    }
  }
  &.input-wrapper--disabled {
    input,.icons  {
     opacity: 0.5;
      pointer-events: none;
      @include tr();
    }
  }
  .icons {
    color: $mainColor;
    font-size: 20px;
    left: 27.5px;
    position: absolute;
    top: 50%;
    @include tr();
    @include transform(translateY(-50%) translateX(-50%));
  }

  input {
    background: #474958;
    border: 1px solid #474958;
    color: $mainColor;
    font-weight: 700;
    height: 35px;
    outline: none;
    padding: 0 20px 0 50px;
    width: 100%;
    @include tr();
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $mainColor;
      opacity: 0.8; /* Firefox */
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    box-shadow: 0 0 0 30px #71748c inset !important;
    -webkit-box-shadow: 0 0 0 30px#71748c inset !important;
    -webkit-text-fill-color: white !important;
}
</style>