<template>
  <div id="app">
    <TheHeader />
  <router-view/>
  <vue-snotify />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
export default {
  components: {
    TheHeader,
  },
  watch:{
    $route(){
       window.scrollTo(0, 0);
    }
  }
};

</script>
<style lang="scss">
@import "@/scss/reset.scss";
@import "@/scss/base.scss";
@import "@/scss/snotify.scss";
@import "~vue-snotify/styles/material";

@font-face {
  font-family: "GoogleSans-Bold";
  src: url("./assets/fonts/GoogleSans-Bold.ttf") format("truetype"),
    url("./assets/fonts/GoogleSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GoogleSans-Medium";
  src: url("./assets/fonts/GoogleSans-Medium.ttf") format("truetype"),
    url("./assets/fonts/GoogleSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GoogleSans-Regular";
  src: url("./assets/fonts/GoogleSans-Regular.ttf") format("truetype"),
    url("./assets/fonts/GoogleSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GoogleSans-Italic";
  src: url("./assets/fonts/GoogleSans-Italic.ttf") format("truetype"),
    url("./assets/fonts/GoogleSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Docker One";
  src: url("./assets/fonts/Docker One.ttf") format("truetype"),
    url("./assets/fonts/Docker One.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Zing Rust Diagonals2 Demo Base";
  src: url("./assets/fonts/ZingRustD2Demo-Base.otf") format("opentype"),
    url("./assets/fonts/ZingRustD2Demo-Base.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Marck Script";
  src: url("./assets/fonts/MarckScript-Regular.ttf") format("truetype"),
    url("./assets/fonts/MarckScript-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

#app {
  font-family: 'GoogleSans-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
    overflow: hidden;
}
html {
  box-sizing: border-box;
  color: #000;
  font-family: 'GoogleSans-Regular', sans-serif;
  scroll-behavior: smooth;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

</style>
