<template >
  <header id="mainHeader">
    <router-link class="logo" to="/">
      <img src="@/assets/logo.svg" alt="" />
    </router-link>

    <div class="links-wrapper">
      <router-link
        class="link"
        v-for="(link, i) of links"
        :key="link.route"
        :to="link.route"
        :class="{ 'router-link-exact-active': checkActive(link.route) }"
      >
        <img
          :src="`${require(`@/assets/header/${i + 1}.svg`)}`"
          class="link-img"
          alt=""
        />
        <span> {{ link.name }} </span>
      </router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: "TheHeader",
  data() {
    return {
      activeRoute: 0,
      links: [
        { name: "НАЧАЛО", route: "/", selected: true },
        { name: "ПРОДУКТИ", route: "/products", selected: false },
        { name: "АКСЕСОАРИ", route: "/accessories", selected: false },
        { name: "ГАЛЕРИЯ", route: "/gallery", selected: false },
        { name: "КОНТАКТИ", route: "/contacts", selected: false },
      ],
    };
  },
  methods: {
    checkActive(route) {
      let active = false;

      if (route !== "/" && this.$route.fullPath.includes(route)) {
        active = true;
      }

      return active;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
header {
  align-items: center;
  background: transparent
    linear-gradient(180deg, #1f2029 0%, #1f2029de 10%, #00000000 100%) 0% 0%
    no-repeat padding-box;
  color: white;
  display: flex;
  flex-direction: column;
  height: 180px;
  position: relative;
  width: 100%;
  z-index: 30;

  &.hidden {
    display: none;
  }

  .logo {
    margin: 15px 0;
    &:hover {
      img {
        transform: scale(0.85, 0.95);
      }
    }
    img {
      @include tr();
    }
  }
  .links-wrapper {
    display: flex;

    &:hover {
      .link {
        opacity: 0.5;
      }
    }

    .link {
      border-bottom: 1px solid transparent;
      display: flex;
      font: normal normal normal 14px/17px Docker One;
      flex-direction: column;
      margin: 0 5px;
      letter-spacing: 0.84px;
      @include tr();
      .link-img {
        display: none;
      }
      &:hover {
        transform: scale(1.048) perspective(0) translateZ(0);
        opacity: 1;
      }
    }

    .router-link-exact-active {
      border-bottom: 1px solid white;
    }
  }
}
@media all and(max-width:$mobile) {
  header {
    .links-wrapper {
      align-items: center;
      background: rgb(27, 22, 33, 0.8);
      bottom: 0px;
      display: flex;
      font-size: 10px;
      height: 110px;
      justify-content: center;
      position: fixed;
      padding: 20px;
      width: 100%;
      z-index: 9999;
      &:hover {
        .link {
          opacity: 1;
        }
      }
      .link {
        align-items: center;
        color: $mainColor;
        font-size: 10px;
        width: 20%;
        .link-img {
          display: block;
          height: 60px;
        }
      }
    }
  }
  .router-link-exact-active {
    border-bottom: 1px solid transparent !important;
    color: white !important;
    img {
      filter: brightness(0) invert(1);
    }
  }
}
</style>

