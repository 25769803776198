<template>
  <main class="content--main">
    <section class="top--header"></section>

    <section
      class="section--content page--content"
      v-observe-visibility="visibilityChanged"
    >
      <div class="title notHome-title">
        <h1 class="notHome-h1">Реализирани обекти</h1>
      </div>
      <p class="description">
        Стронг Строй има над 234 реализирани обекта с участието на Фиброцимент
        за външна и вътрешна облицовка. Част от тях може да видите в нашата
        галерия
      </p>

      <section class="gallery--content">
        <GalleryImages
          :images="firstSection"
          galeryName="Обект 1"
          galeryDescription="Използвани елементи от Фиброцимент - Декоративен камък, Гладка повърхност"
        />

        <GalleryImages
          :images="secondSection"
          galeryName="Обект 2"
          galeryDescription="Използвани елементи от Фиброцимент - Декоративен камък, Гладка повърхност"
        />

        <GalleryImages
          :images="thirdSection"
          galeryName="Обект 3"
          galeryDescription="Използвани елементи от Фиброцимент - Декоративен камък, Гладка повърхност"
        />
        <GalleryImages
          :images="fourthSection"
          galeryName="Обект 4"
          galeryDescription="Използвани елементи от Фиброцимент - Декоративен камък, Гладка повърхност"
        />
        <GalleryImages
          :images="fifthSection"
          galeryName="Обект 5"
          galeryDescription="Използвани елементи от Фиброцимент - Декоративен камък, Гладка повърхност"
        />
        <GalleryImages
          :images="sixthSection"
          galeryName="Обект 6"
          galeryDescription="Използвани елементи от Фиброцимент - Декоративен камък, Гладка повърхност"
        />
        <GalleryImages
          :images="seventhSection"
          galeryName="Обект 7"
          galeryDescription="Използвани елементи от Фиброцимент - Декоративен камък, Гладка повърхност"
        />
      </section>

      <TheFooter />
    </section>
  </main>
</template>

<script>
import GalleryImages from "@/components/GalleryImages.vue";
import TheFooter from "@/components/TheFooter.vue";

export default {
  name: "Gallery",
  components: {
    GalleryImages,
    TheFooter,
  },
  data() {
    return {
      firstSection: [
        { name: "g-3.jpg", folder: "section1" },
        { name: "g-4.jpg", folder: "section1" },
        { name: "g-2.jpg", folder: "section1" },
        { name: "g-1.jpg", folder: "section1" },
      ],
      secondSection: [
        { name: "g-3.jpg", folder: "section2" },
        { name: "g-4.jpg", folder: "section2" },
        { name: "g-2.jpg", folder: "section2" },
        { name: "g-1.jpg", folder: "section2" },
      ],
      thirdSection: [
        // { name: "g-0.mp4", folder: "section3", video: true },
        { name: "g-4.jpg", folder: "section3" },
        { name: "g-2.jpg", folder: "section3" },
        { name: "g-3.jpg", folder: "section3" },
        { name: "g-1.jpg", folder: "section3" },
      ],
      fourthSection: [
        { name: "g-1.jpg", folder: "section4" },
        { name: "g-2.jpg", folder: "section4" },
        { name: "g-3.jpg", folder: "section4" },
        { name: "g-4.jpg", folder: "section4" },
      ],
      fifthSection: [
        { name: "g-1.jpg", folder: "section5" },
        { name: "g-2.jpg", folder: "section5" },
      ],
      sixthSection: [
        { name: "g-1.jpg", folder: "section6" },
        { name: "g-2.jpg", folder: "section6" },
      ],
      seventhSection: [
        { name: "g-1.jpg", folder: "section7" },
        { name: "g-2.jpg", folder: "section7" },
        { name: "g-3.jpg", folder: "section7" },
        { name: "g-4.jpg", folder: "section7" },
        { name: "g-5.jpg", folder: "section7" },
        { name: "g-6.jpg", folder: "section7" },
        { name: "g-7.jpg", folder: "section7" },
        { name: "g-8.jpg", folder: "section7" },
        { name: "g-9.jpg", folder: "section7" },
        { name: "g-10.jpg", folder: "section7" },
      ],
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/home.scss";
.content--main {
  .gallery--content {
    margin: 0 40px;
  }
  .top--header {
    &::before {
      background: url("@/assets/accessories/top.png") no-repeat;
    }
  }
}
@media all and(max-width:$mobile) {
  .title {
    &::before {
      top: -280px !important;
    }
  }
  .gallery--content {
    margin: 0 10px !important;
  }
}
</style>

