<template>
  <main>
    <section
      class="home"
      :class="{ 'home--visible': visible, scrollRight }"
      v-observe-visibility="visibilityChanged"
    >
      <div
        class="feature"
        v-for="(feature, i) in features"
        :class="[
          feature.class,
          visible ? 'feature--visible ' : '',
          i + 1 >= active && !mobile ? 'active ' : '',
          i + 1 === active && mobile ? 'active ' : '',
        ]"
        :key="feature.name"
      >
      <!-- THIS IS FOR THE PLUSES THAT ARE ON THE FAR LEFT ON HERO MOBILE -->
      <template v-if="feature.leftPosition && mobile">
      <img class="plus leftPlus" src="@/assets/home/section-1/intro-animated-plus.svg" alt="plus" />

        <section class="name-and-line name-and-line--left">
          <p class="slide slide--left"></p>
          <div>
            <h1>{{ feature.name }}</h1>
            <img class="line-img" src="@/assets/home/section-1/intro-line-rev.svg" alt="line" />
          </div>
        </section>

        </template>
      <!-- THIS IS FOR THE PLUSES THAT ARE ON THE FAR LEFT ON HERO MOBILE -->
      <template v-else>

        <section class="name-and-line">
          <p class="slide"></p>
          <div>
            <h1>{{ feature.name }}</h1>
            <img class="line-img" src="@/assets/home/section-1/intro-line.svg" alt="line" />
          </div>
        </section>

        <img  class="plus" src="@/assets/home/section-1/intro-animated-plus.svg" alt="plus" />

        </template>
      </div>

      <div class="home-content">
        <img :class="{ 'visia-img--visible': visible }" class="visia-img" src="@/assets/home/section-1/visia_future_animated.svg" alt=""
        />
      </div>
    </section>

    <section class="page--content">
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Form />
      <Contacts />
    </section>
  </main>
</template>

<script>
import Section2 from "@/views/home/Section2.vue";
import Section3 from "@/views/home/Section3.vue";
import Section4 from "@/views/home/Section4.vue";
import Section5 from "@/views/home/Section5.vue";
import Form from "@/views/home/Form.vue";
import Contacts from "@/views/home/HomeContacts.vue";

export default {
  name: "Home",
  components: {
    Section2,
    Section3,
    Section4,
    Section5,
    Form,
    Contacts,
  },
  watch: {
    active() {
      if (this.mobile) {
        this.duration = 6000;

        if (this.features[this.active - 1].hasOwnProperty("right")) {
          this.scrollRight = true;
        } else {
          this.scrollRight = false;
        }
      } else {
        this.duration = 5000;
      }
    },
  },
  data() {
    return {
      // "right" is added when the feature is on the right side of the mobile hero

      // "leftPosition" is added for features that are on the far left side of the mobile hero and their content will be reversed
      features: [
        { name: "Преградна стена", class: "stena", leftPosition: true },
        { name: "Ограда", class: "ograda", leftPosition: true },
        { name: "Челна дъска", class: "duska", right: true },
        { name: "Под", class: "pod" },
        { name: "Декор", class: "dekor", right: true },
        { name: "Фасада", class: "fasada" },
        { name: "Навес", class: "naves", right: true },
        { name: "Подпокрив", class: "pokriv" },
      ],

      active: 0,
      duration: 5000,
      visible: false,
      mobile: false,
      scrollRight: false,
    };
  },
  methods: {
    nextSlide() {
      this.active--;

      if (this.active <= 0) {
        if (!this.mobile) {
          this.active = this.features.length + 1;
        } else {
          this.active = this.features.length;
        }
      }
      this.resetPlay();
    },
    resetPlay() {
      clearInterval(this.timer);
      this.play();
    },
    play() {
      let app = this;
      this.timer = setInterval(function () {
        app.nextSlide();
      }, this.duration);
    },

    visibilityChanged(v) {
      this.visible = v;
      if (v && !this.mobile) {
        this.active = this.features.length + 1;
      } else if (v && this.mobile) {
        this.active = this.features.length;
      } else {
        this.resetPlay();
      }
    },
    handleResize() {
      this.mobile = window.innerWidth <= 1100;
    },
  },
  created: function () {
    this.play();
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/home.scss";
.home {
  align-items: center;
  background: #1f2029 0% 0% no-repeat padding-box;
  height: 105vh;
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -200px;

  &::before {
    content: "";
    background: url("../../assets/home/section-1/hero.jpg") no-repeat;
    background-position: top;
    background-size: cover;
    height: 100%;
    position: absolute;
    opacity: 0.8;
    width: 100%;
    z-index: 2;
  }
  &::after {
    content: "";
    background: transparent
      linear-gradient(to top, #1f2029 0%, #1f2029de 10%, #00000000 100%) 0% 0%
      no-repeat padding-box;
    background-size: 100% 200px;
    background-position: bottom;
    bottom: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  .home-content {
    display: flex;
    justify-content: space-between;
    max-width: $max-width;
    margin: 0 auto;
    height: 100%;
    padding: 60px 40px 20px 40px;
    position: relative;
    width: 100%;
    z-index: 20;

    img {
      width: 30%;
    }
    .visia-img {
      align-self: flex-end;
      max-width: 824px;
      transform: translate(45%, -120%) scale(2);
      width: 60%;
      @include tr();

      &.visia-img--visible {
        transform: initial;
        @include trHome();
        animation: fade 4s ease-in 4s forwards;
      }

      @keyframes fade {
        to {
          opacity: 0;
          visibility: hidden;
          width: 0;
        }
      }
    }
  }
}
.feature {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  visibility: hidden;
  width: fit-content;
  z-index: 25;

  &.active,
  &:hover {
    .name-and-line {
      opacity: 1;
      .slide {
        padding: 0 !important;
        margin:0 !important;
      }
    }
    .plus {
      transform: rotate(45deg);
    }
  }
  &:hover {
    .name-and-line {
      @include tr();
      .slide {
        @include tr();
      }
    }
    .plus {
      @include tr();
    }
  }

  &.feature--visible {
    animation: showFeature 2s ease-in 5s forwards;

    @keyframes showFeature {
      to {
        opacity: 1;
        visibility: visible;
      }
    }

    &.pokriv {
      top: 20%;
      right: 40%;
    }
    &.naves {
      bottom: 39%;
      left: 12%;
    }
    &.fasada {
      top: 45%;
      right: 50%;
    }
    &.pod {
      bottom: 15%;
      right: 30%;
    }
    &.dekor {
      top: 35%;
      left: 45%;
    }
    &.ograda {
      bottom: 25%;
      right: 80%;
    }
    &.stena {
      bottom: 12%;
      right: 85%;
    }
    &.duska {
      top: 28.5%;
      left: 14%;
    }
  }

  .name-and-line {
    display: flex;
    max-width: 220px;
    overflow: hidden;
    opacity: 0;
    @include transition(all 1s 1s cubic-bezier(0.785, 0.135, 0.15, 0.86));

    .slide {
      padding-right: 100%;
      @include transition(all 1s 1s cubic-bezier(0.785, 0.135, 0.15, 0.86));
    }

    div {
      position: relative;
      h1 {
        position: absolute;
        padding-left: 20px;
        white-space: nowrap;
      }
    }
  }

  .plus {
    margin: -45px 0 0 -10px;
    @include transition(all 1s 1s cubic-bezier(0.785, 0.135, 0.15, 0.86));
  }
}

.home-sections {
  background: transparent linear-gradient(0deg, #494b5a 0%, #23232b 100%) 0% 0% no-repeat padding-box;
  position: relative;
  z-index: 1;
}

@media all and(max-width:$mobile) {
  .home {
    height: 149vw;
    min-width: 200%;
    // this animation is added for the hero to move smoothly from the homeSlideActive animation
    animation: homeSlide 1s ease-in 0s forwards;

    @keyframes homeSlide {
      0% {
        transform: translateX(-30%);
      }
      100% {
        transform: initial;
      }
    }
      // this animation is added for the hero to move smoothly from the homeSlideActive animation

    &::before {
      background-position: left;
      background: url("../../assets/home/section-1/hero-mobile.jpg") no-repeat;
      background-size: 100% 120%;
    }

    // slides right when a active feature has "right" property
    &.scrollRight {
      animation: homeSlideActive 1s ease-in 0s forwards;

      @keyframes homeSlideActive {
        to {
          transform: translateX(-30%);
        }
      }
    }
    // slides right when a active feature has "right" property

    .feature {
      opacity: 0 !important;
      visibility: hidden !important;
      @include tr();

      &.feature--visible {
        animation: initial !important;

        &.active {
          opacity: 1 !important;
          visibility: visible !important;
        }

        &.pokriv {
          top: 30%;
          right: 50%;
        }
        &.naves {
          bottom: 30%;
          left: 0%;
        }
        &.fasada {
          top: 50%;
          right: 55%;
        }
        &.dekor {
          top: 40%;
          left: 25%;
        }
        &.pod {
          bottom: 10%;
          right: 40%;
        }
        &.ograda {
          bottom: 15%;
          left:  7%;
        }
        &.stena {
          bottom: 5%;
          left: 12%;
        }
        &.duska {
          top: 38%;
          left: 5%;
        }

        // FAR LEFT PLUSES STYLE
        .slide {
          &.slide--left {
            margin-left: -100%;
            padding-right: 0;
          }
        }
        .name-and-line {
          &.name-and-line--left {
            div {
              h1 {
                padding-right: 25px;
                right: 0;
              }
            }
          }
        }
        .plus {
          &.leftPlus {
            margin: -51px -26px 0 0;
          }
        }
        // FAR LEFT PLUSES STYLE
      }
    }
    .home-content {
      .visia-img {
        animation: fadeMobile 2s ease-in 2s forwards !important;
        height: 100px;
        transform: translate(0%, -200%) scale(1.4);
        max-width: initial;
        width: 50%;

        @keyframes fadeMobile {
          to {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
}
</style>

