<template>
  <main class="section--content home--content">
    <div class="title">
      <h1>Продукти от фиброцимент - имитация на</h1>
    </div>

    <section class="content" v-observe-visibility="visibilityChanged">
      <p class="description">
        Фиброциментът е лек и лесен за транспортиране и нанасяне в сравнение с
        алтернативни каменни материали. Той може да бъде изрязан в желаните
        размери и и до подобни размери на камъка, както и да се боядисва с
        всякаква външна боя на водна основа.
      </p>

      <section class="products--wrapper">
        <main>
          <section
            class="transition--section"
            :class="{ 'products--transition--visible': visible }"
          >
            <div
              v-for="(p, i) in products"
              :key="`${p}-${i}`"
              class="product"
              @click="goToProduct(i)"
            >
              <img
                :src="require(`@/assets/home/section-4/product-${i + 1}.png`)"
                alt="element img"
              />
              <span>{{ p }}</span>
            </div>
            <div class="product"></div>
          </section>
        </main>
      </section>
    </section>
  </main>
</template>

<script>
export default {
  name: "SectionThree",
  data() {
    return {
      products: [
        "Естествен камък",
        "Декоративен камък",
        "Камък",
        "Дърво",
        "Гладка плоскост",
        "Гладки каменни блокчета",
        "Дялан камък",
        "Объл камък",
        "Шахматна тухла",
        "Line",
      ],
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
    goToProduct(i) {
      this.$router.push(`/products?fibercement=${i + 1}`);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/home.scss";
.title {
  position: relative;

  &::before {
    content: "";
    background: url("../../assets/home/section-4/curve.svg") no-repeat;
    background-position: bottom;
    background-size: 130%;
    position: absolute;
    height: 1000%;
    width: 100%;
    bottom: 37px;
    left: 0;
    z-index: -1;
  }
}
.products--wrapper {
  display: flex;
  justify-content: center;
  main {
    background: transparent linear-gradient(180deg, #494b5a 0%, #1f2029 100%) 0%
      0% no-repeat padding-box;
    border-radius: 45px 150px 0 0;
    margin-top: 50px;
    padding: 50px;
    max-width: $max-width;

    .transition--section {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      transform: translateY(200px);
      opacity: 0;
      z-index: 3;
      @include tr();

      &.products--transition--visible {
        transform: initial;
        opacity: 1;
        @include transition($visibleDuration);
      }
      .product {
        background: #313340 0% 0% no-repeat padding-box;
        border: 1px solid #cdd9e52e;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: fit-content;
        margin: 40px 20px;
        width: 15%;
        @include tr();

        &:hover {
          transform: scale(0.9) !important;
          transition: all $duration $cubic !important;

          img {
            transform: scale(1.2);
          }
        }
        &:last-child {
          visibility: hidden;
        }
        img {
          margin-top: -70px;
          width: 100%;
          @include tr();
        }
        span {
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media all and(max-width:$mobile) {
  .title {

  &::before {
      background: url("@/assets/home/curve-4.svg") no-repeat !important	;
      background-position: bottom 0 right -500px  !important;
      width: 140% !important;
      bottom: -420px;
      left: 0 !important;
      }
}
  .products--wrapper {
    main {
      background: initial;
      padding: 0;
      .transition--section {
        width: 100%;
      }
    }
    .product {
      width: 40% !important;
      margin: 40px 10px !important;
    }
  }
}
</style>

