<template>
  <footer>

    <section class="footer-content">
      <main class="contacts-wrapper">
        <div>
          <img src="@/assets/home/section-7/f-map.svg" alt="" />
          <div class="text">
            <p>Централен офис - България, Шумен</p>
            <a href="https://goo.gl/maps/v1R6xR3V1Mvntr7RA" target="_blank">
              <strong>ул.Панайот Волов 42</strong></a
            >
          </div>
        </div>

        <div class="center-contact">
          <img src="@/assets/home/section-7/f-phone.svg" alt="" />
          <div class="text">
            <p>Директни продажби</p>
            <a href="tel:+359896883908" target="_blank">
              <strong>+359 896 883908</strong>
            </a>
            <a href="tel:+359898719431" target="_blank">
              <strong>+359 898 719431</strong>
            </a>
          </div>
        </div>

        <div>
          <img src="@/assets/home/section-7/f-mail.svg" alt="" />
          <div class="text">
            <p>Запитвания по мейл</p>
            <a href="mailto:office@strongstroy.com">
              <strong>office@strongstroy.com</strong>
            </a>
          </div>
        </div>
      </main>

      <main class="img-routes">
        <a href="https://www.strongstroy.com/#!/" target="_blank">
          <img src="@/assets/home/section-7/f-ss.svg" alt="" />
        </a>

        <a href="http://fibercement-bg.com/" target="_blank">
          <img src="@/assets/home/section-7/f-fc.svg" alt="" />
        </a>

        <a
          href="http://betonovielementi.com/betonovielementi#/home"
          target="_blank"
        >
          <img src="@/assets/home/section-7/f-be.svg" alt="" />
        </a>
      </main>
    </section>


    <a href="http://cdots.bg/" target="_blank" class="cdots"
      >Решението е разработено от cDots, 2022</a
    >
  </footer>
</template>

<script>
export default {
  name: "TheHeader",

  data() {
    return {
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
footer {
  position: relative;
  
  &::before {
    content: "";
    background: url("@/assets/home/footer.svg") no-repeat;
    background-position: center;
    background-size: cover;
    height: 400%;
    position: absolute;
    bottom: 35px;
    left: 0;
    width: 100%;
    z-index: -1;
  }
  
  .footer-content {
    align-items: center;
    color: rgb(0, 0, 0, 0.8);
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    max-width: $max-width;
    margin: 0 auto;


    .contacts-wrapper {
      display: flex;
      text-align: left;
      div {
        display: flex;
        justify-content: center;

        .text {
          display: flex;
          flex-direction: column;
          white-space: nowrap;
          strong {
            color: black;
            @include hover();

            &:hover {
              text-decoration: underline;
            }
          }
        }
        img {
          object-fit: scale-down;
          max-width: 20px;
          margin-right: 10px;
        }
        &.center-contact {
          border-right: 2px solid #b5b5b5;
          border-left: 2px solid #b5b5b5;
          margin: 0 10px;
          padding: 0 10px;
        }
      }
    }

    .img-routes {
      img {
        margin: 0 5px;
        opacity: 1;
        width: 30%;
        @include tr();

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
.cdots {
  color: white;
  display: flex;
  margin: 20px auto 0 auto;
  width: fit-content;
  @include hover();
}

@media all and(max-width:$mobile) {
  footer {
    padding-bottom: 100px;

  &::before {
    background: url("@/assets/home/footer-light.svg") no-repeat;
    background-position:  bottom 0px right -700px;
    background-size: cover;
    height: 200%;
    bottom: 60%;
  }

    .footer-content {
      flex-direction: column;
      padding: 20px 5px;

      .contacts-wrapper {
        // align-self: flex-start;
        flex-direction: column;
        margin: 0 0 50px -30px;
        div {
          justify-content: flex-start;

          &.center-contact {
            border: none;
            margin: 15px 0;
            padding: initial;
          }
        }
      }
      .img-routes {
        img {
          filter: invert(1);
        }
      }
    }
  }
}
</style>

