<template>
  <main class="section--content home--content">
    <div class="title centerCurve">
      <h1>Предимства на фиброцимента</h1>
    </div>

    <section class="content" v-observe-visibility="visibilityChanged">
      <p class="description">
        Особеността на материала го прави широко използван не само в
        строителството той намира място като декоративен елемент на фасада,
        покриви, балкони, интериорна декорация. Служи за производство на мебели,
        като и има приложение при изграждането на градини, сгради, веранди.
        Успешно се използва и в мокри помещения като басейни, бани, спа
        центрове. Защитете сградата от различни атмосферни условия.
      </p>

      <section class="benefits--wrapper">
        <div
          class="benefit"
          v-for="(b, i) in benefits"
          :key="`${b}-${i}`"
          :class="{ 'benefit--visible': visible }"
        >
          <img
            :src="require(`@/assets/home/section-3/benefit-${i + 1}.svg`)"
            alt="element img"
          />
          <img
            class="img--hover"
            :src="require(`@/assets/home/section-3/benefit_${i + 1}_loop.svg`)"
            alt="element img"
          />
          <span>{{ b }}</span>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
export default {
  name: "SectionThree",
  data() {
    return {
      benefits: [
        "Клас А1, негорим материал",
        "Добра топло, звукова и хидроизолация",
        "Устойчив на сезонни промени",
        "Устойчив на слънчева светлина",
        "Устойчив на удари",
        "Устойчив на влага и вода",
        "Екологично чист, без азбест",
        "Без мирис и не отделя токсичен газ",
        "Използва се зад плочки на мокри пространства",
        "Устойчив на насекоми, не гниещ, без формоване",
        "Лек, лесен за рязане, пробиване и пренасяне",
        "Лесен за инсталиране и модификация",
      ],
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/home.scss";
main {
  .title {
    position: relative;

    &::before {
      content: "";
      background: url("../../assets/home/curve-2.svg") no-repeat;
      background-position: center;
      background-size: 130%;
      position: absolute;
      height: 1000%;
      width: 100%;
      bottom: -490px;
      left: 0;
      z-index: -1;

      @media all and(min-width:1920px) {
        background-size: cover;
      }
    }
  }

  .content {
    max-width: $max-width;
    margin: 0 auto;

    .benefits--wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 50px;

      .benefit {
        border: 1px solid #cdd9e533;
        border-radius: 54px 9px 52px 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20%;
        margin: 20px;
        padding: 15px 20px;

        transform: translateY(-50%);
        opacity: 0;
        @include tr();

        &:hover {
           border-radius: 9px 54px 24px 52px;
          img {
            display: none;
          }
          .img--hover {
            display: block;
          }
        }
        &.benefit--visible {
          transform: initial;
          opacity: 1;
          @include transition($visibleDuration);
        }
        img,
        .img--hover {
          object-fit: scale-down;
        }
        .img--hover {
          display: none;
        }
        span {
          margin-top: 10px;
        }
      }
    }
  }
}
@media all and(max-width:$mobile) {
  .benefits--wrapper {
    .benefit {
      width: 40% !important;
      margin: 10px !important;
    }
  }
}
</style>

