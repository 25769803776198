<template>
  <main class="content--main">
    <section class="top--header"></section>

    <section class="page--content" v-observe-visibility="visibilityChanged">
      <div class="title notHome-title">
        <h1 class="notHome-h1">Свържете се с нас</h1>
      </div>
      <p class="description">
        Фиброциментът е лек и лесен за транспортиране и нанасяне в сравнение с
        алтернативни каменни материали. Той се произвежда в различни размери и
        дебелина, и с характеристика за негоримост от клас А1. Той може да бъде
        изрязан в желаните размери и до подобни размери на камъка, както и да се
        боядисва с всякаква външна боя на водна основа.
      </p>

      <section class="contacts--content">
        <Form class="form" :visitProductPage="true" />
        <HomeContacts />
      </section>
    </section>
  </main>
</template>

<script>
import Form from "@/views/home/Form.vue";
import HomeContacts from "@/views/home/HomeContacts.vue";
export default {
  name: "Gallery",
  components: {
    Form,
    HomeContacts,
  },
  data() {
    return {};
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/home.scss";
.content--main {
  color: #cdd9e5;
  .form {
    margin-top: 50px;
  }
}
.content--main {
  .top--header {
    &::before {
      background: url("@/assets/accessories/top.png") no-repeat;
    }
  }
}
@media all and(max-width:$mobile) {
  .title {
    &::before {
      top: -280px !important;
    }
  }
}
</style>

