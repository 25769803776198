<template>
  <div
    class="checkbox-container"
    :class="{
      'checkbox-container--disabled': disabled,
      'checkbox-container--invalid': invalid,
    }"
  >
    <label class="checkbox-wrapper">
      <input
        type="checkbox"
        :disabled="disabled"
        :checked="value"
        @change="
          $emit('input', $event.target.checked),
            $emit($event.target.checked ? 'activated' : 'deactivated')
        "
      />
      <span
        class="checkmark"
        :class="{ 'contacts-checkmark': contacts }"
      ></span>
      <p v-if="label">{{ label }}</p>
    </label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: ["value", "label", "disabled", "invalid", "contacts"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.checkbox-container {
  align-items: flex-start;
  display: inline-flex;
  justify-content: space-between;

  &.checkbox-container--disabled {
    opacity: 0.5;
    pointer-events: none;
    @include tr();
  }
}
.checkbox-wrapper {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 18px;
  padding-left: 18px;
  position: relative;
  @include noselect();

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  p {
    color: white;
    margin: 0 0 0 15px;
    a {
      @include hover();
    }
  }
}

/* Create a custom checkbox */
.checkmark {
  border-radius: 2px;
  border: 2px solid white;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  @include tr();
  width: 18px;
}

.checkbox-wrapper:hover input:not(:disabled):not(:checked) ~ .checkmark {
  background-color: rgba($color: white, $alpha: 0.1);
  border: 2px white #1d1d1d;
}
.checkbox-wrapper:hover
  input:not(:disabled):not(:checked)
  ~ .contacts-checkmark {
  border: 2px solid white;
}

.checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-wrapper .checkmark:after {
  border: solid white;
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 4px;
  top: 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 6px;
}

.checkbox-container--disabled {
  .checkbox-wrapper {
    cursor: default;
    input {
      cursor: default;
    }
  }
}

.checkbox-container--invalid {
  .checkmark {
    border: 2px solid $mainError;
  }
}
</style>
