<template>
  <main class="section--content home--content">
    <div class="title">
      <h1>Фиброцимент</h1>
    </div>

    <div class="content" v-observe-visibility="visibilityChanged">
      <section class="content--wrapper">
        <section class="text">
          <p>
            Безспорно е че фиброцимента е естетът и етикета на бъдещите фасадни
            и интериорни дизайни
          </p>

          <section class="element-images mobile">
            <img
              v-for="index in 9"
              :key="index"
              :src="require(`@/assets/home/section-2/element-${index}.png`)"
              alt="element img"
            />
          </section>

          <span>
            Фиброциментовите плоскости са модерни и качествени строителни
            материали. Чрез използването им се ускорява строителния процес,
            повишава се качеството на работа.
          </span>

          <span>
            Фиброцимента e екологично чист материал без азбест с минерализирани
            целулозни влакна и цимент като свързващ елемент. Използва се
            процесът Hatcheck, при който се пресова и слива при висока
            температура и налягане, за да бъде изключително издръжлив срещу най-
            суровите атмосферни условия и с ненадминати технически и
            експлоатационни характеристики.
          </span>

          <span>
            Екстериорът на вашият дом или бизнес сграда е лицето и визитката Ви
            за всички гости и минувачи. Защото външният вид отговаря за първото
            впечатление и имидж на всеки успешен бизнес.
          </span>

          <span>
            Днес фиброцимента не е само фасада а източник на енергия, която
            успешно подобрява енергийната ефективност на сградата . Съвременните
            тенденции диктуват един нов подход в проектирането, като
            същевременно създават модерна, естетична, безопасна и здравословна
            среда с издържан архитектурен облик.
          </span>

          <span>
            Фиброциментът е интелигентният, иновативен и рентабилен начин да
            изразите себе си. Това е вентилируемата фасада на бъдещето, която не
            се влияе от климатичните условия и време. Използвайки го и в
            интериора вашата сграда ще придобие хармоничност. Това е вашето
            безкомпромисно решение, където времето няма думата.
          </span>
        </section>

        <section class="element-images">
          <img
            v-for="index in 9"
            :key="index"
            :class="{ 'img--visible': visible }"
            :src="require(`@/assets/home/section-2/element-${index}.png`)"
            alt="element img"
          />
        </section>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: "SectionTwo",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/home.scss";

main {
  .title {
    position: relative;

    &::after {
      content: "";
      background: url("../../assets/home/section-2/fibrocement.svg") no-repeat;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
    }
    &::before {
      content: "";
      background: url("../../assets/home/curve-1-1366.svg") no-repeat;
      background-position: top;
      background-size: 130%;
      position: absolute;
      height: 750%;
      width: 110%;
      top: 28px;
      left: -60px;
      z-index: -1;
    }
  }

  .content {
    max-width: $max-width;
    margin: 0 auto;

    .content--wrapper {
      display: flex;
      justify-content: center;
      padding: 100px 20px 0 20px;
      margin: 0 40px;
      section {
        width: 50%;
      }

      .text {
        text-align: left;
        p {
          font-size: 21px;
          margin-bottom: 50px;
        }
        span {
          display: block;
          margin: 10px 0;
          letter-spacing: 0px;
        }
      }
      .element-images {
        position: relative;
        &.mobile {
          display: none;
        }
        img {
          position: absolute;
          max-width: 400px;
          top: 0px;
          right: 0%;
          @include tr();

          &.img--visible {
            @include transition($visibleDuration);

            &:nth-child(1) {
              top: 150px;
              right: -6%;
              z-index: 9;
            }
            &:nth-child(2) {
              top: 120px;
              right: -2%;
              z-index: 8;
            }
            &:nth-child(3) {
              top: 90px;
              right: 2%;
              z-index: 7;
            }
            &:nth-child(4) {
              top: 60px;
              right: 6%;
              z-index: 6;
            }
            &:nth-child(5) {
              top: 30px;
              right: 10%;
              z-index: 5;
            }
            &:nth-child(6) {
              top: 10px;
              right: 14%;
              z-index: 4;
            }
            &:nth-child(7) {
              top: -10px;
              right: 18%;
              z-index: 3;
            }
            &:nth-child(8) {
              top: -40px;
              right: 22%;
              z-index: 2;
            }
            &:nth-child(9) {
              top: -70px;
              right: 26%;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

@media all and(max-width:$mobile) {
  .title {
    &::after {
      background-size: contain !important;
    }
    &::before {
            content: "";
      background: url("../../assets/home/curve-1-1366.svg") no-repeat;
      background-position: bottom 0 left -190px  !important;
      background-size: cover !important;
      top: 17px !important;
      left: 0 !important;
    }
  }

  .content {
    flex-direction: column;

    .content--wrapper {
      margin: 0 !important;
      padding: 30px 14px 0 14px  !important;
      section {
        width: 100% !important;
        text-align: center !important;
      }

      .element-images {
        display: none;

        &.mobile {
          display: flex !important;
          height: 320px;
          max-width: 400px;
          margin: 0 auto;
          img {
            position: absolute;
            max-width: 200px;

            &:nth-child(1) {
              top: 105px;
              right: -6%;
              z-index: 9;
            }
            &:nth-child(2) {
              top: 90px;
              right: 0%;
              z-index: 8;
            }
            &:nth-child(3) {
              top: 70px;
              right: 6%;
              z-index: 7;
            }
            &:nth-child(4) {
              top: 55px;
              right: 12%;
              z-index: 6;
            }
            &:nth-child(5) {
              top: 40px;
              right: 18%;
              z-index: 5;
            }
            &:nth-child(6) {
              top: 25px;
              right: 24%;
              z-index: 4;
            }
            &:nth-child(7) {
              top: 10px;
              right: 30%;
              z-index: 3;
            }
            &:nth-child(8) {
              top: -5px;
              right: 36%;
              z-index: 2;
            }
            &:nth-child(9) {
              top: -20px;
              right: 42%;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}
</style>

