<template>
  <main class="wrapper">
    <section
      class="wrapper--content"
      v-observe-visibility="visibilityChanged"
      :class="{ 'wrapper--content--visible': visible }"
    >
      <section class="images">
        <div class="gallery--text">
          <p>{{ galeryName }}</p>

          <span>{{ galeryDescription }}</span>
        </div>

        <div
          class="gallery-carousel"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
        >
          <template v-for="(image, i) of images">
            <transition-group
              :name="`slide-${direction}--long`"
              :key="`transition-${i}`"
              mode="in-out"
              ref="carousel"
            >
              <div
                class="img--container"
                :key="`gallery--image-${i}`"
                v-show="i === active && !image.video"
                :style="{
                  'background-image': `url(${require(`../assets/gallery/${image.folder}/${image.name}`)})`,
                }"
                @click="
                  (previewOpen = true),
                    (selectedImage = image),
                    stopAutoRotation()
                "
              ></div>

              <div
                class="video--box img--container"
                :key="`gallery--video-${i}`"
                v-show="i === active && image.video"
                @click="
                  (previewOpen = true),
                    (selectedImage = image),
                    stopAutoRotation()
                "
              >
                <video preload="metadata" muted playsinline autoplay loop>
                  <source
                    :src="`${require(`@/assets/gallery/${image.folder}/${image.name}#t=0.1`)}`"
                    type="video/mp4"
                  />
                </video>
              </div>
            </transition-group>
          </template>
        </div>
        <div class="toggle-ul-wrapper" v-if="images.length > 1">
          <ul class="toggle-ul">
            <li
              v-for="(index, i) of images"
              :key="`toggle-${i}`"
              :class="{ active: i === active }"
              @click="change(i)"
            ></li>
          </ul>
        </div>
      </section>
    </section>

    <Preview
      v-if="previewOpen"
      :image="selectedImage"
      @close="closeModal"
      @next="next()"
      @prev="prev()"
      @handleTouchStart="handleTouchStart"
      @handleTouchMove="handleTouchMove"
    />
  </main>
</template>

<script>
import Preview from "@/components/Preview.vue";
export default {
  props: ["images", "galeryName", "galeryDescription"],
  components: {
    Preview,
  },
  data() {
    return {
      interval: null,
      speed: 9000,
      active: 0,
      direction: "left",
      dragStartedAt: 0,
      dragOffset: 0,
      xDown: null,
      yDown: null,
      visible: false,

      previewOpen: false,
      selectedImage: "",
    };
  },
  methods: {
    closeModal(update) {
      if (update) {
        this.previewOpen = false;
        this.start();
      }
    },
    visibilityChanged(v) {
      this.visible = v;
      if (v) {
        this.start();
      } else {
        this.stop();
      }
    },
    prev(d, restart) {
      if (restart) {
        this.stop();
      }
      if (d) {
        this.direction = d;
      }
      if (this.active > 0) {
        this.active -= 1;
      } else {
        this.active = this.images.length - 1;
      }
      if (restart) {
        this.start();
      }
      if (this.previewOpen) this.selectedImage = this.images[this.active];
    },
    next(d, restart) {
      if (restart) {
        this.stop();
      }
      if (d) {
        this.direction = d;
      }
      if (this.active < this.images.length - 1) {
        this.active += 1;
      } else {
        this.active = 0;
      }
      if (restart) {
        this.start();
      }
      if (this.previewOpen) this.selectedImage = this.images[this.active];
    },
    start() {
      this.interval = setInterval(() => {
        this.next("left");
      }, this.speed);
    },
    change(n) {
      if (n < this.active) {
        this.direction = "right";
      } else {
        this.direction = "left";
      }

      this.stop();
      this.active = n;
      this.start();
    },
    stop() {
      clearInterval(this.interval);
    },
    stopAutoRotation() {
      clearTimeout(this.interval);
      this.interval = null;
    },
    handleTouchStart(evt) {
      const firstTouch = evt.touches[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
    },
    handleTouchMove(evt) {
      if (!this.xDown || !this.yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = this.xDown - xUp;
      var yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        // left swipe
        if (xDiff > 0) {
          this.next("left", true);
        }
        // right swipe
        else {
          this.prev("right", true);
        }
      }

      this.xDown = null;
      this.yDown = null;
    },
  },
  destroyed() {
    this.stop();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
main {
  margin: 80px auto;
  position: relative;

  .wrapper--content {
    display: flex;
    max-width: $max-width;
    justify-content: center;
    margin: 0 auto;
    transform: translateY(10%);
    opacity: 0;
    @include tr();

    &.wrapper--content--visible {
      transform: initial;
      opacity: 1;
      @include transition($visibleDuration);
    }
  }

  &::after {
    content: "";
    background: url("@/assets/home/section-4/curve.svg") no-repeat;
    background-position: bottom;
    background-size: 130%;
    position: absolute;
    height: 115%;
    width: 100%;
    bottom: 7px;
    left: 0;
    z-index: 2;
    @media all and(min-width:1920px) {
      background-size: cover;
    }
  }

  .images {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0 50px;
    position: relative;
    width: 100%;
    .gallery--text {
      align-items: flex-start;
      color: white;
      display: flex;
      flex-direction: column;
      height: 180px;
      position: absolute;
      padding: 30px;
      text-align: left;
      width: fit-content;
      z-index: 10;
      p {
        font-size: 22px;
        margin-bottom: 10px;
      }
      span {
        font-size: 14px;
        max-width: 300px;
      }
    }
  }
  .gallery-carousel {
    align-content: center;
    display: flex;
    justify-content: center;
    min-height: 85vh;
    height: 100%;
    position: relative;
    width: 100%;

    .video--box {
      overflow: hidden;
      video {
        width: 100%;
      }
    }

    .img--container {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 40px 155px 155px 184px;
      cursor: pointer;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 5;

      &::after {
        content: "";
        background: transparent
          linear-gradient(180deg, #1f2029 0%, #1f2029de 10%, #00000000 100%);
        position: absolute;
        height: 180px;
        width: 100%;
        left: 0;
        z-index: 10;
      }
    }
  }
  .toggle-ul-wrapper {
    align-items: center;
    display: flex;
    position: relative;
    left: 0;
    width: 100%;
    z-index: 7;

    .toggle-ul {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
      li {
        background: #cdd9e599 0% 0% no-repeat padding-box;
        border-radius: 100px;
        border: 1px solid #cdd9e5;
        cursor: pointer;
        display: block;
        height: 20px;
        margin: 0 3px;
        width: 20px;
        &:hover {
          background-color: rgba($color: #cdd9e599, $alpha: 0.5);
        }
        &.active {
          border: 2px solid #cdd9e5;
          background: transparent
            linear-gradient(164deg, #494b5a 0%, #23232b 100%);
        }
      }
    }
  }
  .curve {
    position: absolute;
    width: 100%;
    height: 120%;
    left: 0;
    top: -80px;
    z-index: 1;
  }
}

@media all and(max-width:$mobile) {
  main {
    flex-direction: column;
    margin: 40px auto;

    .images {
      width: 100%;
      padding: 0 20px;

      .img--container {
        border-radius: 50px;
      }
    }
    .gallery-carousel {
      min-height: 350px;
    }
  }
}
</style>