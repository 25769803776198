<template>
  <main class="wrapper" v-observe-visibility="visibilityChanged">
    <section
      class="text"
      v-if="!left && !mobile"
      :class="{ 'text--visible': visible }"
    >
      <slot name="h1" />
      <slot name="span" />
    </section>

    <section class="text" v-if="mobile" :class="{ 'text--visible': visible }">
      <slot name="h1" />
    </section>

    <section class="images" :class="{ 'images--visible': visible }">
      <div
        class="accessories-carousel"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
      >
        <template v-for="(index, i) in size">
          <transition-group
            :name="`slide-${direction}`"
            :key="`transition-${i}`"
            mode="in-out"
            ref="carousel"
          >
            <div
              class="img--container"
              :key="`preview-${i}`"
              v-show="i === active"
              :style="{
                'background-image': `url(${require(`../assets/accessories/${folder}/img-${index}.png`)})`,
              }"
            ></div>
          </transition-group>
        </template>
      </div>
      <div class="toggle-ul-wrapper" v-if="size > 1">
        <ul class="toggle-ul">
          <li
            v-for="(index, i) in size"
            :key="`toggle-${i}`"
            :class="{ active: i === active }"
            @click="change(i)"
          ></li>
        </ul>
      </div>
    </section>

    <section
      class="text"
      v-if="mobile"
      :class="{ 'text--visible': visible }"
    >
      <slot name="span" />
    </section>

    <section
      class="text text--left"
      v-if="left && !mobile"
      :class="{ 'text--visible': visible }"
    >
      <slot name="h1" />
      <slot name="span" />
    </section>
  </main>
</template>

<script>
export default {
  props: ["size", "folder", "left"],
  data() {
    return {
      interval: null,
      speed: 6000,
      active: 0,
      direction: "left",
      dragStartedAt: 0,
      dragOffset: 0,
      xDown: null,
      yDown: null,
      visible: false,
      mobile: false,
    };
  },
  methods: {
    handleResize() {
      if (window.innerWidth <= 1100) {
        this.mobile = true;
      }else{
        this.mobile = false;
      }
    },
    visibilityChanged(v) {
      this.visible = v;
      if (v) {
        this.start();
      } else {
        this.stop();
      }
    },
    prev(d, restart) {
      if (restart) {
        this.stop();
      }
      if (d) {
        this.direction = d;
      }
      if (this.active > 0) {
        this.active -= 1;
      } else {
        this.active = this.size - 1;
      }
      if (restart) {
        this.start();
      }
    },
    next(d, restart) {
      if (restart) {
        this.stop();
      }
      if (d) {
        this.direction = d;
      }
      if (this.active < this.size - 1) {
        this.active += 1;
      } else {
        this.active = 0;
      }
      if (restart) {
        this.start();
      }
    },
    handleTouchStart(evt) {
      const firstTouch = evt.touches[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
    },
    handleTouchMove(evt) {
      if (!this.xDown || !this.yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = this.xDown - xUp;
      var yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        // left swipe
        if (xDiff > 0) {
          this.next("left", true);
        }
        // right swipe
        else {
          this.prev("right", true);
        }
      }

      this.xDown = null;
      this.yDown = null;
    },
    start() {
      this.interval = setInterval(() => {
        this.next("left");
      }, this.speed);
    },
    change(n) {
      if (n < this.active) {
        this.direction = "right";
      } else {
        this.direction = "left";
      }

      this.stop();
      this.active = n;
      this.start();
    },
    stop() {
      clearInterval(this.interval);
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    this.stop();
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.wrapper {
  display: flex;
  gap: 30px;
  justify-content: center;
  max-width: $max-width;
  margin: 9rem auto;
  position: relative;

  &::before {
    content: "";
    background: url("@/assets/accessories/curve.svg") no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    height: 110%;
    width: 110%;
    top: -70px;
    left: -75px;
    z-index: -1;
  }

  .text--left {
    padding-left: 40px;
    padding-right: 0;
  }
  .text {
    text-align: left;
    padding-right: 40px;
    width: 50%;
    z-index: 3;

    transform: translateY(10%);
    opacity: 0;
    @include tr();

    &.text--visible {
      transform: initial;
      opacity: 1;
      @include transition($visibleDuration);
    }

    h1 {
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 20px;
    }

    p,
    span {
      display: block;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    p {
      margin-top: 35px;
    }
  }
  .images {
    align-items: center;
    display: flex;
    justify-items: center;
    flex-direction: column;
    overflow: hidden;
    width: 50%;
    z-index: 3;

    transform: translateY(10%);
    opacity: 0;
    @include tr();

    &.images--visible {
      transform: initial;
      opacity: 1;
      @include transition($visibleDuration);
    }

    .accessories-carousel {
      align-content: center;
      display: flex;
      justify-content: center;
      min-height: 500px;
      height: 100%;
      position: relative;
      width: 100%;

      .img--container {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 40px 155px 155px 184px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
    }
    .toggle-ul-wrapper {
      position: relative;
      z-index: 15;
      .toggle-ul {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0;
        width: 100%;
        li {
          background: #cdd9e599 0% 0% no-repeat padding-box;
          border-radius: 100px;
          border: 1px solid #cdd9e5;
          cursor: pointer;
          display: block;
          height: 20px;
          margin: 0 3px;
          width: 20px;
          &:hover {
            background-color: rgba($color: #cdd9e599, $alpha: 0.5);
          }
          &.active {
            border: 2px solid #cdd9e5;
            background: transparent
              linear-gradient(164deg, #494b5a 0%, #23232b 100%);
          }
        }
      }
    }
  }
}

@media all and(max-width:$mobile) {
  .wrapper {
    flex-direction: column;
    margin: 3rem auto;

    &::before {
      display: none;
    }
    .text,
    .images {
      text-align: center;
      padding: 0 5px;
      width: 100%;

      &::before {
        display: none;
      }

      .img--container {
        border-radius: 50px !important;
      }
    }
    .accessories-carousel {
      max-width: 700px !important;
      height: 65vw !important;
      max-height: 350px !important;
      min-height: 250px !important;
    }
  }
}
</style>