<template>
  <main class="content--main">
    <section class="top--header"></section>

    <section class="section--content page--content">
      <div class="title notHome-title">
        <h1 class="notHome-h1">продукти от фиброцимент - имитация на</h1>
      </div>
      <p class="description">
        Фиброциментът е лек и лесен за транспортиране и нанасяне в сравнение с
        алтернативни каменни материали. Той се произвежда в различни размери и
        дебелина, и с характеристика за негоримост от клас А1. Той може да бъде
        изрязан в желаните размери и до подобни размери на камъка, както и да се
        боядисва с всякаква външна боя на водна основа. Стронг Строй предлага
        следните продукти от Фиброцимент:
      </p>

      <main class="products--wrapper"  v-observe-visibility="visibilityChanged">
        <section class="products--box">
          <div
            v-for="(p, i) in products"
            :key="`${p}-${i}`"
            class="product"
            :class="{
              clickedProduct: clickedProduct === i,
              'product--visible': visible,
            }"
            :style="{ color: p.color }"
            :ref="i + 1"
          >
            <main class="main--content">
              <section class="img--name">
                <img
                  :src="require(`@/assets/home/section-4/product-${i + 1}.png`)"
                  alt="product img"
                />
                <p>фиброцимент</p>
                <span>{{ p.name }}</span>
              </section>

              <section class="parameters">
                <div class="size--title">
                  <p class="size--description">РАЗМЕРИ И ТЕГЛО</p>
                  <span class="size--weight_description"
                    >(ширина x дължина x дебелина - mm)</span
                  >
                </div>

                <div
                  class="parameters--div"
                  v-for="(parameter, i) in p.parameters"
                  :key="`${parameter}-${i}`"
                >
                  <section class="size-weight">
                    <p>{{ parameter.size }}</p>
                    <span>размери лист</span>
                  </section>

                  <section
                    class="section-weight"
                    :style="{ 'border-left': `1px solid ${p.color}` }"
                  >
                    <p>{{ parameter.weight }}</p>
                    <span>тегло на 1 бр. лист</span>
                  </section>
                </div>
              </section>
            </main>
            <Button
              :text="'НАПРАВЕТЕ ЗАПИТВАНЕ'"
              icon="form-send.svg"
              :color="p.color"
              @click="(inquiryModalOpen = true), (inquryProduct = [p, i])"
            ></Button>
          </div>
          <div class="product"></div>
        </section>
      </main>

      <div class="title characteristics--title">
        <h1>технически характеристики на фиброцимента</h1>
      </div>
      <p class="description">
        Фиброциментът е лек и лесен за транспортиране и нанасяне в сравнение с
        алтернативни каменни материали. Той може да бъде изрязан в желаните
        размери и и до подобни размери на камъка, както и да се боядисва с
        всякаква външна боя на водна основа.
      </p>

      <section class="characteristics--wrapper" v-observe-visibility="visibilityChangedSecond">
        <section class="characteristics--box">
          <div
            class="characteristics"
            v-for="c in characteristics"
            :class="{'characteristics--visible':visibleSecond}"
            :key="c.name"
          >
            <p>{{ c.name }}</p>
            <span>{{ c.size }}</span>
          </div>
        </section>
      </section>

      <TheFooter />
    </section>

    <InquiryModal
      v-if="inquiryModalOpen"
      @close="closeModal"
      :product="inquryProduct"
    />
  </main>
</template>

<script>
import Button from "@/components/Button.vue";
import InquiryModal from "@/components/InquiryModal.vue";
import TheFooter from "@/components/TheFooter.vue";

export default {
  name: "Products",
  components: {
    Button,
    InquiryModal,
    TheFooter,
  },
  watch: {
    $route: { handler: "scrollTo" },
  },
  data() {
    return {
      inquiryModalOpen: false,
      inquryProduct: "",
      inquryProductIndex: "",
      clickedProduct: null,
      visible: false,
      visibleSecond: false,

      products: [
        {
          name: "Естествен камък",
          color: "#C8C8C8",
          parameters: [{ size: "1250 x 2500 x 10", weight: "44 кг." }],
        },
        {
          name: "Декоративен камък",
          color: "#969B82",
          parameters: [{ size: "1250 x 2500 x 10", weight: "44 кг." }],
        },
        {
          name: "Камък",
          color: "#A8A8A8",
          parameters: [{ size: "1250 x 2500 x 10", weight: "44 кг." }],
        },
        {
          name: "Дърво",
          color: "#AC8767",
          parameters: [{ size: "1250 x 2500 x 10", weight: "44 кг." }],
        },
        {
          name: "Гладка плоскост",
          color: "#D3BD94",
          parameters: [
            { size: "1250 x 2500 x 8", weight: "35 кг." },
            { size: "1250 x 2500 x 10", weight: "44 кг." },
            { size: "1250 x 2500 x 12", weight: "52 кг." },
            { size: "1250 x 2500 x 16", weight: "70 кг." },
            { size: "1250 x 2500 x 20", weight: "88 кг." },
          ],
        },
        {
          name: "Гладки каменни блокчета",
          color: "#E2875B",
          parameters: [
            { size: "195x400 - 615x2460", weight: "?? кг." },
          ],
        },
        {
          name: "Дялан камък",
          color: "#C8C8C8",
          parameters: [{ size: "1225 x 2500 x 10", weight: "44 кг." }],
        },
        {
          name: "Объл камък",
          color: "#BBB1A1",
          parameters: [{ size: "1225 x 2500 x 10", weight: "44 кг." }],
        },
        {
          name: "Шахматна тухла",
          color: "#C16654",
          parameters: [
            { size: "1225 x 2590 x 10", weight: "44 кг." },
            { size: "1225 x 2590 x 12", weight: "52 кг." },
          ],
        },
        {
          name: "Line",
          color: "#D5D1BF",
          parameters: [
            { size: "1250 x 2500 x 6", weight: "30 кг." },
            { size: "1250 x 2500 x 8", weight: "35 кг." },
            { size: "1250 x 2500 x 10", weight: "44 кг." },
            { size: "1250 x 2500 x 12", weight: "52 кг." },
          ],
        },
      ],

      characteristics: [
        { name: "Стандартни размери:", size: "Гладки и с шарка: 1250 x 2500 mm, 1250 x 3000 mm" },
        { name: "Дебелина:", size: "Гладки: 6 ~ 20 мм — С шарка: 8 ~ 12 мм" },
        { name: "Толеранс на дължина/ширина:", size: "± 5 mm / ± 3,75 mm" },
        {
          name: "Толеранс по дебелина (e: дебелина на плоскостта):",
          size: "Гладки: ±10% e; С шарка: 10% e/+15% e",
        },
        { name: "Правоъгълно отклонение:", size: "± 2 mm/m" },
        {
          name: "Изправеност на страните:",
          size: "± 0,1% а (а: дължина на страната)",
        },
        {
          name: "Външен вид на повърхността:",
          size: "Гладки или с шарка",
        },
        { name: "Плътност:", size: "~1350±50 кг/м3" },
        {
          name: "Фактор на устойчивост на дифузия на пара:",
          size: "μ < 30",
        },
        { name: "Порьозност:", size: "~ 30%" },
        {
          name: "Устойчивост на огъване (минимум)",
          size: "~ 14,0 N/mm2 (надлъжно); ~9,0 N/mm2 (напречно)",
        },
        {
          name: "Устойчивост на замръзване:",
          size: "Устойчив на замръзване в съответствие с TS EN 12467",
        },
        {
          name: "Водонепропускливост:",
          size: "Непропусклив за вода в съответствие с TS EN 12467",
        },
        {
          name: "Устойчивост на пламък:",
          size: "Негорим строителен материал клас А1 съгласно EN 13501-1",
        },
        {
          name: "Съдържание на азбест:",
          size: "Не съдържа азбест (плоскост тип NT)",
        },
        {
          name: "Емисия на други опасни вещества:",
          size: "Няма емисии на опасни вещества или газове",
        },
        { name: "Коефициент на топлинно разширение:", size: "at = 0,005 mm/mk" },
        { name: "Коефициент на топлопреминаване:", size: "λ = 0,20 W/mK " },
        {
          name: "Коефициент на еластичност:",
          size: "8000 N/mm2 (надлъжно), 6000 N/mm2 (хоризонтално)",
        },
        { name: "Степен на водопоглъщане:", size: "<30%" },
        {
          name: "Степен на влажност на плоскостите на склад:",
          size: "<10% (в зависимост от атмосферната влажност)",
        },
        { name: "Воден ефект:", size: "0,5 mm/m (при пълно насищане)" },
        {
          name: "Радиус на огъване (според дебелината):",
          size: "8мм .. 8м / 10мм .. 12м /12мм .. 24м / 16мм .. 50м/",
        },
      ],
    };
  },
  methods: {
    closeModal(update) {
      if (update) {
        this.inquiryModalOpen = false;
      }
    },
    visibilityChanged(v) {
      this.visible = v;
    },
    visibilityChangedSecond(v) {
      this.visibleSecond = v;
    },
    scrollTo() {
      if (this.$route.query.fibercement) {
        const element = this.$refs[this.$route.query.fibercement];
        this.clickedProduct = this.$route.query.fibercement - 1;

        this.$nextTick(() => {
          element[0].scrollIntoView({ behavior: "smooth" });
        });
      }
    },
  },
  mounted() {
    this.scrollTo();
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/home.scss";
.content--main {
  .top--header {
    &::before {
      background: url("@/assets/products/top.png") no-repeat;
    }
  }
  .characteristics--title {
    position: relative;

    &::before {
      content: "";
      background: url("@/assets/products/curve-th.svg") no-repeat;
      background-position: bottom;
      background-size: 130%;
      position: absolute;
      height: 500%;
      width: 110%;
      bottom: 40px;
      left: 0;
      z-index: 1;
    }
  }
  .products--wrapper {
    max-width: $max-width;
    margin: 10px auto;
    .products--box {
      background: transparent linear-gradient(180deg, #494b5a 0%, #1f2029 100%)
        0% 0% no-repeat padding-box;
      border-radius: 153px 50px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: 10px;
      margin: 0 40px;
    }

    .product {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 50px 0;
      opacity: 0;
      transform: scale(1.1);
      position: relative;
      width: 45%;
      z-index: 3;
      @include tr();

      &::before {
        content: "";
        background: url("@/assets/products/curve.svg") no-repeat;
        background-position: center;
        background-size: cover;
        height: 100%;
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 200%;
        z-index: -1;
      }

      &:nth-of-type(even) {
        &::before {
          display: none;
        }
      }

      &.product--visible {
        animation: showProduct 2s forwards;
        @include transition($visibleDuration);

        @keyframes showProduct {
          to {
            opacity: 1;
            transform: initial;
          }
        }

        &.clickedProduct {
          .main--content {
            border: 2px solid inherit;
            border-radius: 20px;
            animation: borderAnimation 0.8s 0.5s linear forwards;
            @include tr();

            @keyframes borderAnimation {
              0% {
                border-top-color: inherit;
                border-right-color: transparent;
                border-bottom-color: transparent;
                border-left-color: transparent;
              }
              25% {
                border-top-color: inherit;
                border-right-color: inherit;
                border-bottom-color: transparent;
                border-left-color: transparent;
              }
              75% {
                border-top-color: inherit;
                border-right-color: inherit;
                border-bottom-color: inherit;
                border-left-color: transparent;
              }

              100% {
                border: 2px solid;
              }
            }
          }
        }
      }

      .main--content {
        display: flex;
        justify-content: space-between;
        padding: 43px 10px;
        margin-bottom: 20px;
        width: 100%;

        .img--name {
          display: flex;
          flex-direction: column;
          p {
            font: normal normal normal 44px/15px Marck Script;
            letter-spacing: -1.98px;
            opacity: 0.2;
          }
          span {
            font: normal normal normal 26px/20px GoogleSans-Regular;
            white-space: nowrap;
          }
          img {
            height: 245px;
            width: 255px;
          }
        }
        .parameters {
          display: flex;
          flex-direction: column;
          flex-basis: 60%;
          text-align: left;

          .size--title {
            margin-bottom: 10px;
            .size--description {
              font: normal normal bold 16px/15px GoogleSans-Bold;
            }
            .size--weight_description {
              font: normal normal normal 12px/15px GoogleSans-Medium;
              letter-spacing: 0px;
              opacity: 0.5;
            }
          }

          .parameters--div {
            display: flex;
            margin: 5px 0;

            .size-weight {
              min-width: 150px;
            }
            .section-weight {
              margin-left: 15px;
              padding-left: 15px;
            }
            p {
              font: normal normal 500 20px/20px GoogleSans-Medium;
              letter-spacing: -0.4px;
              min-width: 100px;
            }
            span {
              font: normal normal normal 14px/20px GoogleSans-Regular;
              letter-spacing: -0.28px;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .characteristics--wrapper {
    margin: 50px auto;
    max-width: 1400px;

    .characteristics--box {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 0 100px;
    }

    .characteristics {
      display: flex;
      width: 100%;
      padding: 5px 20px;
      justify-content: space-between;
      border-bottom: 1px solid #656587;


    transform: translateY(50%);
    opacity: 0;
    @include tr();

    &.characteristics--visible {
      transform: initial;
      opacity: 1;
      @include transition($visibleDuration);
    }

      p,
      span {
        letter-spacing: -0.48px;
        font-size: 19px;
        opacity: 0.7;
      }
      span {
        opacity: 1;
        color: white;
      }
    }
  }
}
@media all and(max-width:$mobile) {
  .characteristics--title {
    &::before {
      background: url("@/assets/home/curve-4.svg") no-repeat !important	;
      background-position: bottom 0 right -500px !important;
      width: 140% !important;
      bottom: -420px !important;
      height: 1000% !important;
      left: 0 !important;
    }
  }

  .products--wrapper {
    .products--box {
      margin: 0 10px !important;
      .product {
        width: 100% !important;

        &::before {
          content: "";
          background: url("@/assets/products/curve.svg") no-repeat;
          background-position: center;
          background-size: cover;
          height: 100%;
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 160%;
          z-index: -1;
        }

        &:nth-of-type(even) {
          &::before {
            display: block !important;
          }
        }

        .main--content {
          flex-direction: column;
          align-items: center;

          .img--name {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  .characteristics--wrapper {
    .characteristics--box {
      margin: 0 10px !important;
      .characteristics {
        flex-direction: column;
      }
    }
  }
}
</style>

