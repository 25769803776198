<template>
  <div class="modal-wrapper">
    <transition name="fade" appear>
      <div class="modal preview">
        <header @click.self="close(true)">
          <img
            class="close"
            @click="close"
            src="@/assets/products/x.svg"
            alt=""
          />
        </header>

        <section
          class="preview--content"
          @click.self="close(true)"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
        >
          <img
            src="@/assets/gallery/l.svg"
            @click="prev()"
            class="arrows prev-arrow"
            alt="letf arrow"
          />
          <img
            src="@/assets/gallery/r.svg"
            @click="next()"
            class="arrows next-arrow"
            alt="right arrow"
          />
          <img
            v-if="!image.video"
            :src="`${require(`@/assets/gallery/${image.folder}/${image.name}`)}`"
            alt=""
          />

          <video v-else autoplay muted loop controls id="myVideo">
            <source
              :src="`${require(`@/assets/gallery/${image.folder}/${image.name}`)}`"
              type="video/mp4"
            />
          </video>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["image"],

  data() {
    return {};
  },

  methods: {
    close(update) {
      this.$emit("close", update);
    },
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    },
    handleKeydown(e) {
      if (e.keyCode === 37) this.prev();
      if (e.keyCode === 39) this.next();
      if (e.keyCode === 27) this.close(true);
    },
    handleTouchStart(event) {
      this.$emit("handleTouchStart", event);
    },
    handleTouchMove(event) {
      this.$emit("handleTouchMove", event);
    },
  },
  mounted() {
    document.body.classList.add("noscroll");
    document.getElementById('mainHeader').classList.add('hidden')
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  destroyed() {
    document.body.classList.remove("noscroll");
    document.getElementById('mainHeader').classList.remove('hidden')
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/modal.scss";
.modal-wrapper{
  backdrop-filter: blur(80px);
}
.preview--content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  height: 85%;
  > .arrows {
    cursor: pointer;
    position: absolute;
    top: 50%;
    object-fit: scale-down;
    min-height: 30%;
    z-index: 2;
    @include transform(translateY(-50%));
    &.prev-arrow {
      left: 11%;
    }
    &.next-arrow {
      right: 11%;
    }
  }

  img,
  video {
    width: auto;
    height: auto;
  }
}
@media all and(max-width:$mobile) {
  .preview--content {
    > .arrows {
      width: 20px;
      @include transform(translateY(-80%));
      &.prev-arrow {
        left: 3%;
      }
      &.next-arrow {
        right: 3%;
      }
    }

    img,
    video {
      margin-top: 20%;
      height: 50%;
      width: 100%;
      max-width: 600px;
    }
  }
}
</style>
