<template>
  <div
    @click="$emit('click')"
    class="button"
    :style="{ background: color ? color : '#cbb78f' }"
  >
    <section class="img--section">
      <img
        v-if="icon"
        class="icons"
        :src="`${require(`@/assets/home/section-6/${icon}`)}`"
        alt="icon"
      />
    </section>
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "ButtonMain",
  props: ["color", "text", "icon"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.button {
  align-items: center;
  border-radius: 10px;
  background: #cbb78f;
  border: 0;
  color: black;
  display: flex;
  font-size: $px14;
  font-weight: 600;
  height: 45px;
  padding: 25px 30px;
  @include hover();
  @include tr();

  &:hover {
    transform: scale(0.95);
    .img--section {
      img {
        margin-right: 5px;
      }
    }
  }
  .img--section {
    display: flex;
    justify-content: flex-end;
    min-width: 30px;
    img {
      margin-right: 10px;
      @include tr();
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>