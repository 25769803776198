<template>
  <main class="section--content home--content">
    <div class="title">
      <h1>Изпратете ни запитване</h1>
    </div>

    <section class="content" v-observe-visibility="visibilityChanged">
      <p class="description">
        Фиброциментът е лек за транспортиране и нанасяне в сравнение с
        алтернативни каменни материали.Той може да бъде изрязан в желаните
        размери или до подобните размери на камъка, както и да се боядисва с
        всякаква външна боя на водна основа.
      </p>

      <section class="form-and-elements-image">
        <div class="form">
          <Input
            type="text"
            :id="'name'"
            placeholder="Име и фамилия"
            :disabled="loading"
            :invalid="invalidName"
            @input="invalidName = false"
            required="true"
            v-model="form.name"
            icon="form-name.svg"
            class="input"
          />

          <Input
            type="email"
            :id="'email'"
            placeholder="Email"
            :disabled="loading"
            :invalid="invalidEmail"
            @input="invalidEmail = false"
            required="true"
            v-model="form.email"
            icon="form-mail.svg"
            class="input"
          />

          <Input
            type="phone"
            id="phone"
            :inputmode="true"
            placeholder="Телефон"
            :disabled="loading"
            :invalid="invalidPhone"
            @input="invalidPhone = false"
            required="true"
            v-model="form.phone"
            icon="form-phone.svg"
            class="input"
          />
          <Input
            type="text"
            placeholder="Запитване"
            :disabled="loading"
            :invalid="invalidMessage"
            @input="invalidMessage = false"
            required="true"
            v-model="form.message"
            icon="form-text.svg"
            class="input"
          />

          <main class="checkbox-andbutton">
            <section>
              <Checkbox
                v-model="form.agreed"
                :disabled="loading"
                :label="'Съгласен съм'"
                class="checkbox"
              />

              <p class="aggrement-text">
                Тази форма за контакти събира персонална информация с цел
                обратна връзка при запитване от ваша страна.Чрез маркиране на
                отметката , вие доброволно се съгласявате да ни предоставите
                тази информация
              </p>
            </section>

            <section>
              <Button
                @click="submit"
                :disabled="loading"
                :text="'Изпрати'"
                icon="form-send.svg"
              ></Button>
            </section>
          </main>
          <main class="visitProductPage" v-if="visitProductPage">
            <p>
              За да направите запитване за конкретен продукт, вижте
              <router-link class="link" to="/products"
                >страница Продукти</router-link
              >
            </p>
          </main>
        </div>

        <img
          src="@/assets/home/section-6/elements.png"
          alt=""
          :class="{ 'img--visible': visible }"
        />
      </section>
    </section>
  </main>
</template>

<script>
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import Checkbox from "@/components/Checkbox.vue";
export default {
  name: "Form",
  props: ["visitProductPage"],
  components: {
    Input,
    Button,
    Checkbox,
  },
  data() {
    return {
      loading: false,
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
        agreed: false,
      },
      invalidName:false,
      invalidEmail:false,
      invalidPhone:false,
      invalidMessage:false,
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
    isValid() {
      if (!this.form.name) {
        this.invalidName = true
        this.$snotify.warning("Въведете вашите имена");
        return false;
      } else if (!this.form.email || !this.validateEmail(this.form.email)) {
        this.invalidEmail = true;
        this.$snotify.warning("Въведете коректен имейл");
        return false;
      } else if (
        !this.form.phone ||
        !this.validatePhoneNumber(this.form.phone)
      ) {
        this.invalidPhone = true;
        this.$snotify.warning("Въведете коректен телефон");
        return false;
      } else if (!this.form.message) {
        this.invalidMessage = true;
        this.$snotify.warning("Съобщението не може да е празно");
        return false;
      } else if (!this.form.agreed) {
        this.$snotify.warning("Трябва да се съгласите с Общите условия");
        return false;
      }

      return true;
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatePhoneNumber(phone) {
      const re = /^[0-9 ]+$/;
      return re.test(String(phone).toLowerCase());
    },
    submit() {
      if (this.isValid()) {
        this.loading = true;
        fetch(`${process.env.VUE_APP_BASE_URI}/email/inquiry`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.form),
        })
          .then((response) => response.json())
          .then((data) => {
            this.success = data.success;
            if (data.success) {
              this.form = {
                name: "",
                email: "",
                phone: "",
                message: "",
                agreed: false,
              };
            }
          })
          .catch((err) => console.log(err))
          .then(() =>
            setTimeout(() => {
              this.loading = false;
              this.$snotify.success("Изпратихте успешно запитване");
            }, 1000)
          );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/home.scss";
.title {
  position: relative;

  &::before {
    content: "";
    background: url("../../assets/home/section-6/curve-1.svg") no-repeat;
    background-position: top;
    background-size: 130%;
    position: absolute;
    height: 1100%;
    width: 100%;
    top: 32px;
    left: 0;
    z-index: -1;
  }
}
.form-and-elements-image {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: $max-width;
  margin: 0 auto;
  position: relative;
  z-index: 15;

  .form {
    display: flex;
    flex-direction: column;
    margin-right: -125px;
    margin-left: 50px;
    position: relative;
    z-index: 1;
    .input {
      margin-bottom: 10px;
    }

    .checkbox-andbutton {
      align-items: center;
      display: flex;
      text-align: left;
      margin-top: 20px;

      section {
        margin-left: 15px;
        position: relative;
        z-index: 20;
      }

      .agreement {
        span {
          font: normal normal normal 14px/17px GoogleSans-Italic;
        }
      }
      .aggrement-text {
        margin-top: 10px;
        font-size: 10px;
        max-width: 70%;
      }
    }
    .visitProductPage {
      text-align: left;
      margin-bottom: -50px;
      margin-top: 50px;
      padding: 0 15px;
      max-width: 80%;

      p {
        font-size: 22px;
      }

      .link {
        text-decoration: underline;
        color: #cbb78f;
        @include hover();
      }
    }
  }

  img {
    width: 60%;
    transform: translateX(200%);
    @include transition($visibleDuration);
    &.img--visible {
      transform: initial;
    }
  }
}
@media all and(max-width:$mobile) {
  .title {

  &::before {
      background-position: bottom 0 left -210px  !important;
      background-size: cover !important;
      top: 17px !important;
      left: 0 !important;
  }
}
  .form-and-elements-image {
    flex-direction: column;
    max-width: 500px;
    .form {
      margin: 50px 0 0 0;

    .input {
      margin-bottom: 25px;
    }

      .checkbox-andbutton {
        align-items: center;
        display: flex;
        text-align: left;
        margin-top: 20px;
        section {
          margin-left: 15px;
          position: relative;
          z-index: 20;
        }

        .agreement {
          span {
            font: normal normal normal 14px/17px GoogleSans-Italic;
          }
        }
        .aggrement-text {
          max-width: 100%;
        }
      }
          .visitProductPage {
      margin-bottom: 10px;
      max-width: 100%;
    }
    }
    img {
      width: 100%;
    }
  }
}
</style>
