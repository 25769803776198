<template>
  <main class="section--content contacts">
    <div class="title">
      <h1>За нас, контакти</h1>
    </div>

    <section class="content">
      <p class="description">
        Фирмата е специализирана в проектиране на обществени, жилищни и
        туристически сгради, строително-монтажни и ремонтни работи в областта на
        промишленото и гражданското строителство, доставка и продажба на
        строителни материали, конструкции и съоръжения и търговия с недвижими
        имоти.
      </p>

      <section>


        <div class="links-wrapper">
        <div class="mobile-about">
        <p>За Стронг Строй</p>
        <span
          >Strong Stroy е дружество с ограничена отговорност, създадено през
          2006 г. Фирмата е специализирана в проектиране на обществени, жилищни
          и туристически сгради, строително-монтажни и ремонтни работи в
          областта на промишленото и гражданското строителство, доставка и
          продажба на строителни материали, конструкции и съоръжения и търговия
          с недвижими имоти. През годините Стронг Строй прерасна в модерна
          фирма, отговаряща на съвременните изисквания за качество. Фирмата се
          налага като надежден партньор с високото качество и кратките срокове
          на изпълнение на обектите.</span
        >
        </div>
          <div v-for="a of array" :key="a" class="link">
            <img src="@/assets/home/section-7/f-ul.svg" alt="" />
            <p>{{ a }}</p>
          </div>
        </div>

        <TheFooter />
      </section>
    </section>
  </main>
</template>

<script>
import TheFooter from "@/components/TheFooter.vue";
export default {
  name: "Contacts",
  components: {
    TheFooter,
  },
  data() {
    return {
      array: [
        "Цялостно изпълнение и въвеждане в експлоатация на жилищни, промишлени и търговски сгради",
        "Строителство на обекти от инфраструктура",
        "Изграждане и ремонти на вътрешни водопроводни, канализационни, отоплителни системи",
        "Изработване и монтаж на метални конструкции, елементи за производствени халета, съоръжения, сгради и други",
        "Полагане на шлафан и щампован бетон",
        "Полагане на индустриални подови настилки",
        "Направа на фасадни и покривни облицовки и изолации",
        "Индустриално строителство",
        "Инвестиции в областта на недвижимите имоти",
        "Изграждане на плувни басейни и СПА центрове",
        "Търговия с фиброцимент с най-високо качество",
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/home.scss";
.contacts {
  color: #cdd9e5;
  display: flex;
  flex-direction: column;
  background: hsl(0, 0%, 0%, 0.2) 0% 0% no-repeat padding-box;
  position: relative;
  padding-bottom: 20px;

  &::before {
    content: "";
    background: url("../../assets/home/section-7/f-img.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  .title {
    position: relative;
    &::before {
      content: "";
      background: url("../../assets/home/curve-2.svg") no-repeat;
      background-position: center;
      background-size: 130%;
      position: absolute;
      height: 1000%;
      width: 100%;
      top: -500px;
      left: 0;
      z-index: -1;

            @media all and(min-width:1920px){
        background-size: cover;
      }
    }
  }

.mobile-about{
  display: none;
}
  .links-wrapper {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px 30px;
    max-width: $max-width;
    margin: 0 auto;
    .link {
      display: flex;
      margin: 5px 0;
      color: white;
      text-align: left;
      max-width: 550px;
      font-size: 13px;
      img {
        margin-right: 5px;
      }
    }
  }
}
@media all and(max-width:$mobile) {
        .title {
    &::before {
        background: url("../../assets/home/curve-4.svg") no-repeat !important	;
      background-position: left !important;
      width: 130% !important;
      top:  -320px !important;
      left: -85px !important;
      
      }
  }
  .contacts {
      background: initial;
  &::before {
    content: "";
    background: url("../../assets/home/section-7/ftr-img-mob.jpg") no-repeat;
    background-position: top 70px right 0;
    background-size: 100% 500px;
    border-radius: 150px 0 0 0 !important;
    opacity: 0.7;
  }

  .links-wrapper {
    padding: 300px 14px 20px 14px;
  }
    .mobile-about{
      display: block;
      margin-bottom: 20px;

    p{
      font-size: 18px;
    }
    }
  }
}
</style>
