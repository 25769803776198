<template>
  <main class="section--content home--content">
    <div class="title">
      <h1>Приложения на фиброцимента</h1>
    </div>

    <section class="content" v-observe-visibility="visibilityChanged">
      <p class="description">
        Влакнестите плочи, които добавят стойност към сградите както визуално,
        така и технически намират широко приложение. Фиброцимента може да бъде
        навсякъде във вашият дом така че да придобие модерен стил, безопасна
        среда без да е нужна поддръжка. Особеността на материала го прави широко
        използван не само в строителството той намира място като декоративен
        елемент на фасада, покриви, балкони, интериорна декорация. Служи за
        производството на мебели, както и има приложение при изграждането на
        градини, огради , веранди.Успешно се използва и в мокри помещения като
        басейни, бани, спа центрове. Комбинира се добре и с други и суровини
        като внася освен стил и уют
      </p>

      <section class="section-5--images">
        <section class="images--box">
          <main class="first-img-wrapper">
            <div class="first-img" :class="{ 'image--visible': visible }"></div>
          </main>
          <main class="second-img-wrapper">
            <div
              class="second-img"
              :class="{ 'image--visible': visible }"
            ></div>
          </main>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
export default {
  name: "SectionFive",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/home.scss";
.title {
  position: relative;

  &::before {
    content: "";
    background: url("../../assets/home/curve-2.svg") no-repeat;
    background-position: center;
    background-size: 130%;
    position: absolute;
    height: 1000%;
    top: -500px;
    left: 0;
    width: 100%;
    z-index: 1;

    @media all and(min-width:1920px) {
      background-size: cover;
    }
  }
}
.description {
  position: relative;
  z-index: 2;
}
.section-5--images {
  margin: 100px auto 0px auto;
  max-width: $max-width;
  position: relative;
  z-index: 2;

  &::before {
    content: "";
    background: url("@/assets/home/section-5/curve-2.svg") no-repeat;
    background-position: center;
    position: absolute;
    height: 250%;
    top: -450px;
    left: -220px;
    width: 100%;
    z-index: -2;
  }
  .images--box {
    display: flex;
    justify-content: space-between;
    margin: 0 40px;

    main {
      height: 600px;
      width: 48%;
      overflow: hidden;
      &.first-img-wrapper {
        border-radius: 31px 172px 31px 155px;
      }
      &.second-img-wrapper {
        display: flex;
        justify-content: flex-end;
        border-radius: 40px 155px 155px 184px;
      }

      div {
        height: 100%;
        width: 20%;
        @include tr();
        &.image--visible {
          width: 100%;
          @include transition($visibleDuration);
        }

        &.first-img {
          background: url("../../assets/home/section-5/img-1.jpg") no-repeat;
        }
        &.second-img {
          background: url("../../assets/home/section-5/img-2.jpg") no-repeat;
        }
      }
    }
  }
}
@media all and(max-width:$mobile) {
  .title {
    &::before {
      content: "";
      background: url("@/assets/home/curve-4.svg") no-repeat !important	;
      background-position: bottom 0 right -500px !important;
      width: 140% !important;
      top: -370px !important;
      height: 1000% !important;
      left: 0 !important;
    }
  }

  .section-5--images {
    margin: 50px 0 0 0;
    &::before {
      background: url("@/assets/home/section-5/curve-mobile.svg") no-repeat;
      background-position: center;
      background-size: 305% 550%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .images--box {
      flex-direction: column;
      margin: 0 14px;

      main {
        max-height: 260px;
        width: 100%;
        &.first-img-wrapper {
          border-radius: 30px 30px 116px 30px;
        }
        &.second-img-wrapper {
          border-radius: 116px 30px 30px 30px;
          margin: 50px 0 0 0;
        }
        div {
          background-size: cover !important;
          &.second-img {
            background-position: right !important;
          }
        }
      }
    }
  }
}
</style>

