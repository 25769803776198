<template>
  <main class="content--main">
    <section class="top--header"></section>

    <section
      class="section--content page--content"
      v-observe-visibility="visibilityChanged"
    >
      <div class="title notHome-title">
        <h1 class="notHome-h1">
          Аксесоари, профили, крепежни елементи, монтаж
        </h1>
      </div>
      <p class="description">
        Фиброциментът може да бъде навсякъде във вашият дом така че да придобие
        модерен стил, безопасна среда без да е нужна поддръжка. Особеността на
        материала го прави широко използван не само в строителството той намира
        място като декоративен елемент на фасада, покриви, балкони, интериорна
        декорация.
      </p>

      <section class="accesoaries--content">
        <ImagesAndText :folder="'section1'" :size="1">
          <h1 slot="h1">Декоративно покритиe</h1>
          <div slot="span">
            <span
              >Декоративното покритие има не само естетически и защитен ефект
              върху външните повърхности на сградите.</span
            >
            <span
              >Плочите, които могат да бъдат трансформирани в различни форми
              благодарение на техните характеристики за обработка и
              персонализиране, имат хомогенна структура.</span
            >
            <span>
              Най-важната разлика, която отличава продукта от другите материали
              за покритие е, че продуктьт, който лесно се нанася върху плоски
              повърхности, може да се използва върху бродирани фасади на
              класическа архитектура, ако желаете.</span
            >
            <span>
              Тъй като е лек материал, той натоварва сградата по-малко в
              сравнение с други материали. Възможността за удобно приложение
              както в модерни, така и в класически текстурирани конструкции на
              сгради дава на материала гъвкава употреба.</span
            >
            <span>
              Осигурявайки много привилегии със своите леки и издръжливи
              характеристики, фиброцимента е много повече от декоративно
              покритие той предоставя превъзходни предимства в сравнение с
              цената и времето за приложение на сглобяемите системи.</span
            >
          </div>
        </ImagesAndText>

        <ImagesAndText :folder="'section2'" :size="1" :left="true">
          <h1 slot="h1">Кит</h1>
          <div slot="span">
            <span>
              Това е много лека шпакловка на водна основа за запълване и ремонт
              на повърхности на базата на смес от модифицирани полимери и
              акрилна емулсия. След изсъхване никога не се срутва и не се
              напуква и запазва своята гъвкавост.</span
            >
            <p>МЕСТА ЗА УПОТРЕБА</p>

            <span
              >Използва се като материал за ремонт и запълване на фуги в
              гипсокартон, GRC сглобяеми плоскости на вътрешни и външни фасади.
              Освен това; EPS се използва като фуги и ремонтна паста при монтажа
              на изолационни плочи на базата на XPS. Малките пукнатини и счупени
              точки се ремонтират бързо и надеждно. Дава успешни резултати при
              много работи по запълване на фуги, като коректор на повърхността
              на фугите на гипсови плоскости, ремонт и затваряне на отвори за
              винтове при монтажа на циментови сглобяеми плоскости. Неговата
              разлика в сравнение с шпакловки с подобна цел е, че никога не се
              срутва и не се напуква. Прилепва към много материали като бетон,
              дърво, метал, EPS, XPS, стъкло, камък, кожа, гъба и др.</span
            >

            <p>ПРЕДИМСТВА</p>
            <span>– Не се срутва и не се напуква</span>
            <span>– Много лесен за оформяне</span>
            <span> – Много е лек</span>
            <span>– Запазва своята гъвкавост след изсъхване</span>
            <span>– Устойчив на влага и атмосферни условия</span>
            <span>– Много лесен и бърз за шофиране</span>
            <span>– Може лесно да се боядисва с вътрешни или външни бои.</span>
          </div>
        </ImagesAndText>

        <ImagesAndText :folder="'section3'" :size="3">
          <h1 slot="h1">Профили</h1>
          <div slot="span">
            <span
              >Общ текст за профилите - още не е даден. Профили М и J - Използва
              се за направата на конструкция при монтаж на вентилируема фасада,
              предстенни обшивки, тавани и други. Пета за профил - Свързващ
              елемент, с който се монтира профилът за фасадата. Явява се важен
              елемент от цялостното изграждане на конструкцията</span
            >
          </div>
        </ImagesAndText>
      </section>

      <TheFooter />
    </section>
  </main>
</template>

<script>
import ImagesAndText from "@/components/ImagesAndText.vue";
import TheFooter from "@/components/TheFooter.vue";

export default {
  name: "Accessories",
  components: {
    ImagesAndText,
    TheFooter,
  },
  data() {
    return {};
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/home.scss";
.content--main {
  .accesoaries--content {
    margin: 0 40px;
  }
  .top--header {
    &::before {
      background: url("@/assets/accessories/top.png") no-repeat;
    }
  }
}
@media all and(max-width:$mobile) {
  .accesoaries--content {
    margin: 0 10px !important;
  }
}
</style>

